<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		:click-to-close="false"
		name="userHasHardBounce"
		transition="scale"
		height="auto"
		@vue:mounted="$emit('mounted')"
	>
		<div class="justify-center text-center">
			<h3 class="mt-4">Bewerbung unvollständig</h3>
			<p>
				Deine Bewerbungsunterlagen sind unvollständig. Bitte vervollständige deine Daten, um dich
				bewerben zu können.
			</p>
			<HokButton fullwidth="always" class="mb-4" color="main" to="/pwa/review-profile">
				Jetzt vervollständigen
			</HokButton>
			<HokButton color="main" fullwidth="always" is-text @click="$modal.hide('userHasHardBounce')">
				Nein Danke
			</HokButton>
		</div>
	</HokModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'HardBounceDialog', emits: ['mounted'] });
</script>
