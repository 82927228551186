<template>
	<div>
		<slot />
	</div>
</template>

<script lang="ts">
import * as Swing from '@hokify/swing';
import { defineComponent } from 'vue';

// Register events
const events = [
	'throwout',
	'throwoutend',
	'throwoutdown',
	'throwoutleft',
	'throwoutright',
	'throwoutup',
	'throwin',
	'throwinend',
	'dragstart',
	'dragmove',
	'dragend',
	'destroyCard'
];

export default defineComponent({
	name: 'NuxtSwing',
	events,
	data() {
		const observer = undefined as undefined | MutationObserver;
		const cards: any[] = [];
		const stack = undefined as any;

		return {
			stack,
			cards,
			observer
		};
	},
	methods: {
		createCard(el: HTMLElement) {
			try {
				return this.stack?.createCard(el);
			} catch (err) {
				console.warn('Error creating card', err);
			}
		},
		removeCard(el: HTMLElement) {
			try {
				const card = this.getCard(el);
				if (card !== null) {
					this.cards.splice(this.cards.indexOf(card), 1);
					this.stack.destroyCard(card);
				}
			} catch (err) {
				console.warn('Error removing card', err);
			}
		},
		getCard(el: HTMLElement) {
			try {
				return this.stack?.getCard(el);
			} catch (err) {
				console.warn('Error getting card', err);
			}
		}
	},
	mounted() {
		this.stack = Swing.Stack(this.config || {});
		const children = [...this.$el.children];
		children.forEach(el => {
			this.cards.push(this.createCard(el));
		});

		// Observe changes in DOM
		this.observer = new MutationObserver(mutations => {
			const addedElements: any[] = [];
			const removedElements: any[] = [];
			mutations.forEach(({ addedNodes, removedNodes }) => {
				// https://developer.mozilla.org/en-US/docs/Web/API/NodeList
				// eslint-disable-next-line
				for (let i = 0; i < addedNodes.length; i += 1) {
					addedElements.push(addedNodes[i]);
				}
				// eslint-disable-next-line
				for (let i = 0; i < removedNodes.length; i += 1) {
					removedElements.push(removedNodes[i]);
				}
			});

			// Create a new card for each new element
			addedElements.forEach(el => {
				// Ignore if the added element is also removed
				const i = removedElements.indexOf(el);
				if (i !== -1) {
					removedElements.splice(i, 1);
					return;
				}

				const card = this.getCard(el);
				if (card === null) {
					this.cards.push(this.createCard(el));
				}
			});

			// Remove the card if the element is gone
			removedElements.forEach(el => this.removeCard(el));
		});
		this.observer.observe(this.$el, { childList: true });

		events.forEach(event => {
			this.stack.on(event, e => {
				this.$emit(event, e);
			});
		});
	},
	beforeUnmount() {
		this.observer?.disconnect();
	},
	props: {
		config: { type: Object, default: () => ({}) }
	}
});
</script>
