<template>
	<main>
		<Swipe :show-active-sourcing-modal="showActiveSourcingModal" :user-found-job="userFoundJob" />
	</main>
</template>

<script lang="ts">
import { isObjectId } from '@hokify/shared-components-nuxt3/lib/helpers/utility';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { defineNuxtComponent, definePageMeta, useRoute, useSeoMeta } from '#imports';
import Swipe from '@/components/user/job/Swipe.vue';
import { useCompanyStore } from '@/stores/company';
import { useRelationsStore } from '@/stores/relations';
import { useUserProfileStore } from '@/stores/user-profile';

interface IHttpErrorResponse {
	response: {
		status: number;
		data?: {
			code?: string;
			msg?: string;
			message?: string;
		};
	};
}

function isHTTPError(err: any): err is IHttpErrorResponse {
	return err.response && typeof err.response?.status === 'number';
}

export default defineNuxtComponent({
	name: 'JobSwipe',
	components: { Swipe },
	setup() {
		definePageMeta({
			layout: 'user',
			path: '/pwa/:jobNrOrJobIdOrCompanyId?',
			// non present or number or APITypeObjectId with leading J or C
			validate: async route => {
				const value = Array.isArray(route.params.jobNrOrJobIdOrCompanyId)
					? route.params.jobNrOrJobIdOrCompanyId[0]
					: route.params.jobNrOrJobIdOrCompanyId;

				return (
					!value ||
					/^\d+$/.test(value) ||
					(typeof value === 'string' &&
						(value.startsWith('J') || value.startsWith('C')) &&
						isObjectId(value.slice(1)))
				); // cut away the leading J or C
			}
		});

		useSeoMeta({
			title: 'Jobs finden und bewerben | hokify',
			robots: 'index, follow'
		});
	},
	async asyncData({ $pinia }) {
		const { query, params } = useRoute();
		const companyStore = useCompanyStore($pinia);
		const relationsStore = useRelationsStore($pinia);
		const userProfileStore = useUserProfileStore($pinia);
		// non present or must be a number
		const jobNrOrJobIdOrCompanyId = Array.isArray(params.jobNrOrJobIdOrCompanyId)
			? params.jobNrOrJobIdOrCompanyId[0]
			: params.jobNrOrJobIdOrCompanyId;
		const validJobNr = /^\d+$/.test(jobNrOrJobIdOrCompanyId);
		const validJobId =
			typeof params.jobNrOrJobIdOrCompanyId === 'string' &&
			params.jobNrOrJobIdOrCompanyId.startsWith('J') &&
			isObjectId(params.jobNrOrJobIdOrCompanyId.slice(1)); // cut away the leading J

		const validCompanyId =
			typeof params.jobNrOrJobIdOrCompanyId === 'string' &&
			params.jobNrOrJobIdOrCompanyId.startsWith('C') &&
			isObjectId(params.jobNrOrJobIdOrCompanyId.slice(1)); // cut away the leading C

		if (validJobNr || validJobId) {
			let job;
			// still get the job of the page url and some other stuff from the server
			try {
				if (validJobNr) {
					job = await relationsStore.getJob({ jobNr: params.jobNrOrJobIdOrCompanyId });
				} else {
					job = await relationsStore.getJobById(params.jobNrOrJobIdOrCompanyId.slice(1));
				}
			} catch (err) {
				if (isHTTPError(err) && err.response.status === 404) {
					throw createError({
						statusCode: 404,
						message: 'Job existiert nicht'
					});
				}

				if (isHTTPError(err)) {
					throw createError({
						statusCode: err.response.status,
						message:
							err.response.data && err.response.data.code
								? `${err.response.data.code}: ${err.response.data.msg || err.response.data.message}`
								: `Es ist ein Fehler aufgetreten${
										err.response.data ? ` (${JSON.stringify(err.response.data)})` : ''
									}`
					});
				}

				throw createError(err as Error);
			}

			if (job) {
				// build suggestion-like jobWrapper object, so mutations and actions still work
				const jobWrapper = {
					type: 'matchrelation',
					identifier: `matchrelation:job:${job._id}`,
					relation: {
						type: 'job',
						obj: job
					}
				};
				relationsStore.setSearchresults({
					totalJobs: 1,
					list: [jobWrapper]
				});

				return {
					job,
					title: job?.name
				};
			}
		}

		// if param is no valid jobNr try to resolve company
		if (validCompanyId) {
			try {
				const companyDetail = await companyStore.getCompany(jobNrOrJobIdOrCompanyId.slice(1));
				if (companyDetail.company) {
					// build suggestion-like companyWrapper object, so mutations and actions still work
					const companyWrapper = {
						type: 'matchrelation',
						identifier: `matchrelation:company:${companyDetail.company._id}`,
						relation: {
							type: 'company',
							obj: companyDetail.company
						}
					};
					relationsStore.setSearchresults({
						totalJobs: 1,
						list: [companyWrapper]
					});

					return {
						job: companyDetail.company,
						title: companyDetail.company?.name
					};
				}
			} catch (err) {
				if (isHTTPError(err) && err.response.status === 404) {
					throw createError({
						statusCode: 404,
						message: 'Job/Company existiert nicht'
					});
				}

				if (isHTTPError(err)) {
					throw createError({
						statusCode: err.response.status,
						message:
							err.response.data && err.response.data.code
								? `${err.response.data.code}: ${err.response.data.msg || err.response.data.message}`
								: `Es ist ein Fehler aufgetreten${
										err.response.data ? ` (${JSON.stringify(err.response.data)})` : ''
									}`
					});
				}

				throw createError(err as Error);
			}
		}

		// if it is no jobNr, jobId, companyId, load cards per default
		try {
			const jobs = await relationsStore.loadJobCards(
				false,
				userProfileStore?.obj?.testerGroup === 'B'
			);

			const job = (jobs && jobs[0]) || {};
			let userFoundJob;
			if (query.userFoundJob === 'yes') {
				userFoundJob = 'yes';
			} else if (query.userFoundJob === 'no') {
				userFoundJob = 'no';
			}

			return {
				job,
				title: job?.name,
				showActiveSourcingModal: query?.showActiveSourcingModal === 'true',
				userFoundJob
			};
		} catch (err) {
			throw createError({
				statusCode: 500,
				message: 'Es ist ein Fehler aufgetreten'
			});
		}
	},
	data() {
		const userFoundJob = undefined as undefined | 'yes' | 'no';
		const showActiveSourcingModal = false;
		const title = 'Jobs finden und bewerben | hokify';
		return {
			userFoundJob,
			showActiveSourcingModal,
			title
		};
	},
	// Remove on feature-flag "suggestionsDiversity" cleanup
	// Removes serverside pre-fetched results to overwrite it with feature-flagged results
	async mounted() {
		if (!lsTest()) {
			return;
		}

		// Checking only localstorage for feature-flag as we only want to test it locally
		const suggestionsDiversity = localStorage.getItem('suggestionsDiversity') === 'true';

		if (suggestionsDiversity) {
			const relationsStore = useRelationsStore(this.$pinia);
			relationsStore.resetlastIdentifier();
			relationsStore.setSearchresults({
				totalJobs: 0,
				list: []
			});
			await relationsStore.loadJobCards(suggestionsDiversity);
		}
	}
});
</script>
