<template>
	<div
		v-if="job"
		:data-card-identifier="suggestion.identifier"
		class="pane jobcard"
		@click="openJobDetail"
	>
		<div class="jobcard-inner">
			<div class="job_head">
				<SwipeCardHeaderImage
					:cover-image="`${job && job.images && job.images.medium ? job.images.medium : ''}`"
					:logo="job && job.company && job.company.logos && job.company.logos.small"
					@share-card="shareCard(job)"
				/>
				<div
					v-if="job.selectedCandidate"
					class="text-color-white bg-color-coral py-5 text-center -mx-5"
				>
					Dieses Unternehmen zeigt<br /><b>Interesse</b> an dir!
				</div>
				<div class="job_info">
					<div class="content">
						<div class="flex">
							<h1 class="job_name mb-2 flex-1">
								<span itemprop="title">
									{{ job.name }}
								</span>
							</h1>
							<HokIcon
								v-if="$nuxt?.$matchesStore.getStatusOfRelation(suggestion.relation) === 'saved'"
								name="icon:bookmark"
								pointer
								color="main"
								@click.stop="$emit('save-job')"
							/>
							<HokIcon
								v-else
								name="icon:bookmark-outline"
								pointer
								color="main"
								@click.stop="$emit('save-job')"
							/>
						</div>

						<h2
							v-if="job.company && job.company.name"
							class="company_name text-base mb-3 pb-2 bold"
						>
							{{ job.company.name }}
						</h2>
						<JobFacts
							:job="job"
							:user-location="getJobFilter && getJobFilter.location"
							:gross-net="false"
						/>

						<div class="rqf-wrapper">
							<template v-if="job.requiredFields && job.requiredFields.length > 0">
								<JobRequiredFields :required-fields="job.requiredFields" />
							</template>
						</div>
					</div>
				</div>
			</div>
			<div v-if="job && job.description" class="description-container relative">
				<div class="job-description border-t border-color-grey-lightest">
					<BeautifyText :text="job.description" />
				</div>
				<div class="job-description-gradient" />
				<div class="job-description-gradient" />
			</div>
		</div>
		<div class="flex justify-center">
			<HokButton outline color="main" class="absolute z-30 bottom-3 text-sm" size="small"
				>Mehr Info</HokButton
			>
		</div>
		<div class="overlay like-image" />
		<div class="overlay dislike-image" />
	</div>
</template>

<script lang="ts">
import BeautifyText from '@hokify/shared-components-nuxt3/lib/components/BeautifyText.vue';
import SwipeCardHeaderImage from '@hokify/shared-components-nuxt3/lib/components/SwipeCardHeaderImage.vue';
import JobRequiredFields from '@hokify/shared-components-nuxt3/lib/components/JobRequiredFields.vue';
import JobFacts from '@hokify/shared-components-nuxt3/lib/components/JobFacts.vue';
import type { IAPIJobFilter, IAPIJobForUser } from '@hokify/common';
import { defineComponent } from 'vue';
import type Vue from 'vue';
import { shareJob } from '../helpers/sharejob';

export default defineComponent({
	name: 'JobCard',
	components: {
		JobRequiredFields,
		BeautifyText,
		JobFacts,
		SwipeCardHeaderImage
	},
	data() {
		const shareCard: (this: Vue, job: IAPIJobForUser) => void = shareJob;
		return {
			shareCard
		};
	},
	computed: {
		job(): IAPIJobForUser {
			return this.suggestion.relation.obj;
		},
		getJobFilter(): IAPIJobFilter | undefined {
			return this.$nuxt?.$userProfileStore?.obj?.jobFilter;
		}
	},
	methods: {
		openJobDetail() {
			this.$emit('click', {
				force: false,
				job: this.job
			});
		}
	},
	props: {
		suggestion: { type: Object, required: true }
	},
	emits: {
		'save-job': () => true,
		click: (_params: { force: boolean; job: IAPIJobForUser }) => true
	}
});
</script>

<style scoped src="../assets/styles/card.scss" lang="scss" />
