<template>
	<div>
		<HokModal
			:adaptive="!!$isMobile.any"
			:width="$isMobile.any ? '95%' : '350px'"
			:show-close-button="false"
			click-to-close
			name="askForActiveSourcing"
			transition="scale"
			v-bind="$attrs"
			@vue:mounted="$emit('mounted')"
		>
			<div class="flex flex-wrap justify-center text-center">
				<NuxtImg src="/pwa-core-nuxt3/svg/onboarding/radar.svg" />
				<h3 class="mt-4">Ziehe Jobangebote an!</h3>
				<p>Stelle dein Profil sichtbar und Unternehmen kommen mit Jobangeboten auf dich zu!</p>
				<HokButton fullwidth="always" class="mb-4" color="main" to="/pwa/profile/settings">
					Jetzt einstellen
				</HokButton>
				<HokButton color="main" fullwidth="always" outline @click="$emit('result', false)">
					Nein, danke</HokButton
				>
			</div>
		</HokModal>
	</div>
</template>

<script lang="ts">
import type { IAPILoginUser } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import AskPrivacyForActiveSourcing from './AskPrivacyForActiveSourcing.vue';

export default defineComponent({
	name: 'AskForActiveSourcing',
	components: {
		AskPrivacyForActiveSourcing
	},
	props: {
		user: { type: Object as PropType<IAPILoginUser>, required: true }
	},
	emits: ['result', 'mounted']
});
</script>
