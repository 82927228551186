<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		:show-close-button="false"
		click-to-close
		name="addToHomeScreen"
		transition="scale"
		v-bind="$attrs"
	>
		<div class="flex flex-wrap justify-center text-center">
			<NuxtImg alt="hokify" src="/shared-components-nuxt3/svgs/hokify/logo-hy-b2c.svg" />
			<h3 class="mt-4">zum Startbildschirm hinzufügen</h3>
			<p>Rufe hokify schnell und einfach über den Startbildschirm auf</p>
			<HokButton fullwidth="always" class="mb-4" color="main" @click="$emit('result', true)">
				Hinzufügen
			</HokButton>
			<HokButton color="main" fullwidth="always" is-text @click="$emit('result', false)">
				Nein Danke
			</HokButton>
		</div>
	</HokModal>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	emits: ['result']
});
</script>
