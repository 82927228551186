<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '500px'"
		show-close-button
		click-to-close
		classes="v--modal noPadding whiteCloseIcon"
		class="rounded-t-md"
		name="userFoundJob"
		transition="scale"
		@vue:mounted="$emit('mounted')"
	>
		<template v-if="userFoundJob === 'yes'">
			<NuxtImg alt="Gratulation zu deinem neuen Job!" src="/job-found.png" class="rounded-t-md" />
			<div class="text-center p-8">
				<h6 class="mt-4">Gratulation zu deinem neuen Job! 🎉</h6>
				<p class="text-sm mb-8">
					Schau doch mal in unserem Blog vorbei, um zu erfahren worauf es in deinem neuen Job
					ankommt!
				</p>
				<HokButton color="main" to="/pwa/profile/magazine"> Mehr erfahren </HokButton>
			</div>
		</template>
		<template v-else-if="userFoundJob === 'no'">
			<NuxtImg alt="Gib nicht auf!" src="/job-not-found.png" class="rounded-t-md" />
			<div class="text-center p-8">
				<h6 class="mt-4">Gib nicht auf! 💪</h6>
				<p class="text-sm mb-8">
					Dein Traumjob wartet auf dich da draußen - du musst ihn dir nur schnappen! Tipp: Teste
					verschiedene Filtereinstellungen.
				</p>
				<HokButton color="main" @click="$modal.hide('userFoundJob')"> Jetzt Job finden </HokButton>
			</div>
		</template>
	</HokModal>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { useUserFoundJobStore } from '@/stores/user-found-job';

export default defineComponent({
	name: 'UserFoundJobModal',
	emits: ['mounted'],
	computed: {
		...mapStores(useUserFoundJobStore)
	},
	mounted() {
		if (this.userFoundJob === 'yes') {
			this.userFoundJobStore.userFoundJobDecision(true);
		} else if (this.userFoundJob === 'no') {
			this.userFoundJobStore.userFoundJobDecision(false);
		}
	},
	props: {
		userFoundJob: { type: String, required: false, default: undefined }
	}
});
</script>
