<template>
	<div class="relative" style="padding-bottom: 65%">
		<HokBgImg :source="coverImage" class="bg-no-repeat absolute inset-0 bg-center" />
		<div
			v-if="editJobMode"
			class="absolute inset-0 group flex items-center justify-center hover:bg-color-white/20"
			@click="$emit('cover-clicked')"
		>
			<HokIcon name="icon:edit" color="white" :size="20" class="hidden group-hover:block" />
		</div>
		<div class="absolute left-2 bottom-2" @click="$emit('logo-clicked')">
			<div
				v-if="logo"
				class="w-16 h-16 relative border border-color-text rounded-lg bg-color-white"
			>
				<HokBgImg
					:source="logo"
					ssr
					alt="Firmenlogo"
					class="absolute inset-0 bg-no-repeat bg-center"
					:bg-cover="false"
				/>
			</div>
			<div
				v-else-if="companyName"
				class="w-16 h-16 relative bg-color-grey-lightest border border-color-text rounded-lg"
			>
				<div
					class="uppercase absolute inset-0 flex items-center justify-center"
					style="font-size: 60px"
				>
					{{ companyName.charAt(0) }}
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import HokBgImg from './HokBgImg.vue';

export default defineComponent({
	name: 'SwipeCardHeaderImage',
	components: { HokBgImg },
	props: {
		coverImage: { type: String, required: true },
		logo: { type: String, required: false },
		companyName: { type: String, default: '' },
		editJobMode: { type: Boolean, default: false }
	},
	emits: ['cover-clicked', 'logo-clicked']
});
</script>
