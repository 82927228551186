<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		name="jobRecModal"
		transition="scale"
		height="auto"
		click-to-close
		@vue:mounted="$emit('mounted')"
		@close-button="closeModal"
		@click-closed="closeModal"
	>
		<div class="flex flex-col items-center text-center pt-4">
			<NuxtImg src="/job-rec.png" class="aspect-square mb-4" />
			<h3 class="mt-4">Swipe dich zum Job!</h3>
			<p class="mb-9">
				Job passt? Dann swipe nach rechts. Je mehr passende Jobs du speicherst, desto treffsicherer
				werden unsere Vorschläge!
			</p>
			<HokButton fullwidth="always" color="main" class="mb-4" @click="closeModal">
				Los geht's!
			</HokButton>
		</div>
	</HokModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';

export default defineComponent({
	name: 'JobRecDialog',
	emits: ['mounted'],
	methods: {
		closeModal() {
			this.$trackUserEvent?.('job_rec_explainer_view', {});
			if (lsTest()) {
				localStorage.setItem('jobRecModalSeen', 'true');
			}
			this.$modal.hide('jobRecModal');
		}
	}
});
</script>
