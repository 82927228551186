<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		:click-to-close="false"
		name="welcomeBackModal"
		transition="scale"
		height="auto"
		@vue:mounted="$emit('mounted')"
	>
		<div class="justify-center text-center">
			<div class="bg-color-main rounded-full w-28 h-28 inline-block" style="line-height: 7rem">
				<HokIcon class="m-auto inline-block" color="white" :size="14" name="icon:hand" />
			</div>
			<h3 class="mt-4">Willkommen zurück!</h3>
			<p>
				Dein letzter Besuch ist schon etwas her, bringe jetzt dein Profil auf den neuesten Stand.
			</p>
			<HokButton fullwidth="always" class="mb-4" color="main" to="/hokifycv">
				Profil bearbeiten
			</HokButton>
			<HokButton color="main" fullwidth="always" is-text @click="$modal.hide('welcomeBackModal')">
				Später
			</HokButton>
		</div>
	</HokModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'WelcomeBackDialog', emits: ['mounted'] });
</script>
