export const prepareJobFilterObject = jobFilter => {
	const location = Array.isArray(jobFilter.currentLocation)
		? { geo: jobFilter.currentLocation }
		: { address: jobFilter.currentLocation };

	const search =
		jobFilter.searchMode === 'categories'
			? { fieldIds: jobFilter.selectedFields }
			: { searchterm: jobFilter.searchterm };

	const calculatedRadius = jobFilter.currentRadius
		? parseInt(jobFilter.currentRadius, 10)
		: undefined;

	return {
		search,
		location,
		region: jobFilter.tld || undefined,
		radius: calculatedRadius,
		filters: jobFilter.selectedFilters || []
	};
};
