<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		:show-close-button="false"
		click-to-close
		name="askForJobAlarm"
		transition="scale"
		v-bind="$attrs"
	>
		<div v-if="getJobFilter" class="flex flex-wrap justify-center text-center">
			<NuxtImg src="/shared-components-nuxt3/images/jobalarm.png" />
			<h3 class="mt-4">Aktiviere jetzt den Jobalarm für deine aktuelle Suche!</h3>
			<p>
				Erhalte neue Jobs per E-Mail und verpasse nie wieder einen Job aus den
				<span v-if="getJobFilter.fieldIds.length">{{ getJobFilter.fieldIds.length }}</span> von dir
				ausgewählten Branchen.
			</p>
			<HokIcon v-if="getJobFilter.location" name="icon:pin-2" :size="5" color="text" />
			<p v-if="getJobFilter.location">{{ getJobFilter.location.name }}</p>
			<p v-if="getJobFilter.filters && getJobFilter.filters.length">
				Und
				<span v-if="getJobFilter.filters.length > 1"
					>{{ getJobFilter.filters.length }} weitere von dir gesetzte Filter.</span
				>
				<span v-else>{{ getJobFilter.filters.length }} weiterer von dir gesetzter Filter.</span>
			</p>
			<HokButton fullwidth="always" class="mb-4" color="main" @click="$emit('result', true)">
				Jobalarm erstellen
			</HokButton>
			<HokButton color="main" fullwidth="always" is-text @click="$emit('result', false)">
				Nein Danke
			</HokButton>
		</div>
	</HokModal>
</template>
<script lang="ts">
import type { IAPIJobFilter } from '@hokify/common';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	name: 'AskForJobAlarm',
	emits: ['result'],
	computed: {
		getJobFilter(): IAPIJobFilter | undefined {
			return this.userProfileStore?.obj?.jobFilter;
		},
		...mapStores(useUserProfileStore)
	}
});
</script>
