import type { IAPICompany } from '@hokify/common';

export const shareCompany = function shareCompany(company: IAPICompany) {
	const nuxt = tryUseNuxtApp();
	if (nuxt) {
		nuxt.$trackUserEvent?.('share_company', { item_id: company._id });
	}
	if (process.env.cordova && window.plugins?.socialsharing) {
		const options = {
			message: company && company.name,
			subject: 'Firma auf hokify gefunden',
			url: company && company.webUrl
		};
		window.plugins.socialsharing.shareWithOptions(options);
	} else if (navigator.share) {
		navigator.share({
			title: 'Firma auf hokify gefunden!',
			text: company && company.name,
			url: company && company.webUrl
		});
	} else {
		window.open(company && company.webUrl, '_blank');
	}
};
