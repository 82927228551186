<template>
	<div
		v-if="company"
		:data-card-identifier="suggestion.identifier"
		class="jobcard"
		@click="openCompanyDetail"
	>
		<div class="h-full overflow-hidden">
			<SwipeCardHeaderImage :cover-image="coverImage" @share-card="shareCompany(company)" />
			<div class="ribbon">
				<span v-if="company.swipeAction === 'discover'">jobs<br />entdecken</span>
				<span v-else>initiativ<br />bewerben</span>
			</div>
			<div class="-mt-4 mb-4">
				<div class="flex justify-center h-16 mb-2">
					<div
						v-if="company.logos"
						class="w-16 h-16 relative border border-color-text rounded-lg bg-color-white"
					>
						<HokBgImg
							:source="company.logos"
							size="medium"
							alt="Firmenlogo"
							class="absolute inset-0 bg-no-repeat bg-center rounded-lg"
							:bg-cover="false"
						/>
					</div>
					<div
						v-else
						class="w-16 h-16 relative bg-color-grey-lightest border border-color-text rounded-lg"
					>
						<div
							v-if="company && company.name"
							class="uppercase absolute inset-0 flex items-center justify-center"
							style="font-size: 60px"
						>
							{{ company.name.charAt(0) }}
						</div>
					</div>
				</div>
				<h1
					v-if="company && company.name"
					class="text-center text-xl break-words leading-normal mb-0 lg:mb-1 mt-2 lg:mt-0 text-color-text font-bold px-4"
				>
					{{ company.name }}
				</h1>
				<Location :company="company" size="small" />
			</div>
			<div class="description-container mx-4 border-t border-color-grey-lightest">
				<template v-if="company.description">
					<h4 class="font-light text-color-text mb-0 text-sm pt-4">Über Uns</h4>
					<BeautifyText
						v-if="company.description"
						:text="company.description"
						class="mb-2 text-xs"
					/>
				</template>
				<template v-if="company && company.fields && company.fields.length">
					<Fields :company="company" :show-all-fields="false" size="small" />
				</template>
				<div class="job-description-gradient" />
				<div class="job-description-gradient" />
			</div>
		</div>
		<div class="flex justify-center">
			<HokButton outline class="absolute z-30 bottom-3 text-sm" size="small" color="main"
				>Mehr Info</HokButton
			>
		</div>
		<div class="overlay like-image" />
		<div class="overlay jobalarm-image" />
		<div class="overlay dislike-image" />
	</div>
</template>

<script lang="ts">
import BeautifyText from '@hokify/shared-components-nuxt3/lib/components/BeautifyText.vue';
import HokBgImg from '@hokify/shared-components-nuxt3/lib/components/HokBgImg.vue';
import SwipeCardHeaderImage from '@hokify/shared-components-nuxt3/lib/components/SwipeCardHeaderImage.vue';
import type { IAPICompany } from '@hokify/common';
import Location from '@hokify/shared-components-nuxt3/lib/components/Company/Location.vue';
import Fields from '@hokify/shared-components-nuxt3/lib/components/Company/Fields.vue';
import { defineComponent } from 'vue';
import { shareCompany } from '../helpers/shareCompany';

export default defineComponent({
	name: 'CompanyCard',
	components: {
		BeautifyText,
		HokBgImg,
		SwipeCardHeaderImage,
		Location,
		Fields
	},
	data() {
		const showAllFields = false;
		return {
			showAllFields,
			shareCompany
		};
	},
	computed: {
		company(): IAPICompany {
			return this.suggestion.relation.obj;
		},
		coverImage(): string {
			if (this.company?.coverImage?.medium) {
				return this.company.coverImage.medium;
			}

			return '/pwa-core-nuxt3/img/company-placeholder.jpg';
		}
	},
	methods: {
		openCompanyDetail() {
			this.$emit('click', {
				force: false,
				company: this.company,
				audienceId: this.suggestion.relation.audienceId
			});
		}
	},
	props: {
		suggestion: { type: Object, required: true }
	},
	emits: ['click']
});
</script>

<style scoped src="../assets/styles/card.scss" lang="scss" />
