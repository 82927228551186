<template>
	<div v-if="suggestion" :data-card-identifier="suggestion.identifier" class="pane jobcard">
		<div class="jobcard-inner" @click="openInfoDetail">
			<div class="job_head">
				<!-- <SwipeCardHeaderImage :job="job" @share-card="shareJob(job)" />-->
				<div class="job_info">
					<div class="content">
						<!-- <span class="info-button" @click.stop.prevent="openInfoDetail(true, $event)">-->
						<!-- i-->
						<!-- </span>-->
						<h1 class="job_name">
							<span itemprop="title">
								{{ suggestion.title }}
							</span>
						</h1>
					</div>
				</div>
			</div>
			<div class="description-container relative">
				<div class="job-description">
					<BeautifyText :text="suggestion.text" />
				</div>
				<div class="job-description-gradient" />
				<div class="job-description-gradient" />
			</div>
		</div>
		<div class="overlay like-image" />
		<div class="overlay dislike-image" />
	</div>
</template>

<script lang="ts">
import BeautifyText from '@hokify/shared-components-nuxt3/lib/components/BeautifyText.vue';
import SwipeCardHeaderImage from '@hokify/shared-components-nuxt3/lib/components/SwipeCardHeaderImage.vue';
import JobRequiredFields from '@hokify/shared-components-nuxt3/lib/components/JobRequiredFields.vue';
import JobFacts from '@hokify/shared-components-nuxt3/lib/components/JobFacts.vue';
import { defineComponent } from 'vue';
import { shareJob } from '../helpers/sharejob';

export default defineComponent({
	name: 'InfoCard',
	components: {
		JobRequiredFields,
		BeautifyText,
		JobFacts,
		SwipeCardHeaderImage
	},
	emits: ['click'],
	data() {
		return {
			shareJob
		};
	},
	methods: {
		openInfoDetail(force) {
			this.$emit('click', {
				force,
				info: this.suggestion
			});
		}
	},
	props: {
		suggestion: { type: Object, required: true }
	}
});
</script>

<style scoped src="../assets/styles/card.scss" lang="scss" />
