import { defineStore } from 'pinia';

export const useUserFoundJobStore = defineStore('userFoundJob', {
	state: () => ({}),
	actions: {
		async userFoundJobDecision(decision: boolean) {
			try {
				this.$nuxt.$hokFetch('app-api/jobseeker/findjobdecision', {
					method: 'PUT',
					body: {
						yesnodecision: decision
					}
				});
			} catch (error: any) {
				console.error('put findjobdecision failed', error);
				throw error.response?.data || error.response || error;
			}
		}
	}
});
