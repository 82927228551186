<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		:click-to-close="false"
		name="userCvParseNotVerified"
		transition="scale"
		height="auto"
		@vue:mounted="$emit('mounted')"
	>
		<h3 class="mt-4">Überprüfe deine Profildaten</h3>
		<p class="mb-9">
			Dein Lebenslauf wurde in dein Profil übertragen. Überprüfe jetzt, ob alles stimmt.
		</p>
		<HokButton fullwidth="always" class="mb-4" color="main" to="/hokifycv?reviewcv=true">
			Jetzt überprüfen
		</HokButton>
		<HokButton
			color="main"
			fullwidth="always"
			is-text
			@click="$modal.hide('userCvParseNotVerified')"
		>
			Später
		</HokButton>
	</HokModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'CvParseDialog', emits: ['mounted'] });
</script>
