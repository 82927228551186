<template>
	<div>
		<HardBounceDialog v-if="hasHardBounce" @mounted="openModal('userHasHardBounce')" />
		<CvParseDialog
			v-if="cvParseVerified === false"
			@mounted="openModal('userCvParseNotVerified')"
		/>
		<JobRecDialog
			v-if="showJobRecommendation && showRecModal"
			@mounted="openModal('jobRecModal')"
		/>
		<WelcomeBackDialog v-if="shouldSeeWelcomeBackModal" @mounted="openModal('welcomeBackModal')" />
	</div>
</template>

<script lang="ts">
import type { IAPILoginUser } from '@hokify/common';
import { diff } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/diff';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import JobRecDialog from '@/components/JobRecDialog.vue';
import WelcomeBackDialog from '@/components/WelcomeBackDialog.vue';
import HardBounceDialog from '@/components/HardBounceDialog.vue';
import CvParseDialog from '@/components/CvParseDialog.vue';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	name: 'SwipeDialogues',
	components: {
		WelcomeBackDialog,
		HardBounceDialog,
		CvParseDialog,
		JobRecDialog
	},
	data() {
		return {
			showRecModal: false
		};
	},
	mounted() {
		if (lsTest() && localStorage.getItem('jobRecModalSeen') === null) {
			this.showRecModal = true;
		}
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		shouldSeeWelcomeBackModal(): boolean {
			return !!(
				this.userProfileStore.obj?.lastActivity &&
				diff(this.userProfileStore.obj.lastActivity, 'days') > 30 * 4
			);
		},
		hasHardBounce(): boolean {
			return !!this.userProfileStore.obj?.reviewResult?.failedCvCriteria?.length;
		},
		cvParseVerified(): boolean | undefined {
			return this.userProfileStore.obj?.cvInfo?.verifiedByUser;
		},
		...mapStores(useUserProfileStore)
	},
	methods: {
		openModal(modal) {
			this.$modal.show(modal);
		}
	},
	props: {
		showJobRecommendation: { type: Boolean, default: false }
	}
});
</script>
