<template>
	<!-- on desktop just clicking and swiping in any direction is possible, strict swiping rules are mobile only -->
	<div @click="$emit('likeOrDislike')">
		<template v-if="userRootStore.topLevelDomain === 'de'">
			<NuxtSwing
				v-show="tutorialIndex === 0"
				:config="swingConfigDislike"
				class="current-stack cursor-pointer"
				@throwoutleft="$emit('dislike')"
			>
				<div
					class="max-h-[550px] rounded-lg backdrop-blur-md bg-color-white/40 absolute inset-0 overflow-hidden"
				>
					<NuxtImg src="/svg/swipe-dislike.svg" class="absolute top-5 left-5 pointer-events-none" />
					<NuxtImg
						src="/svg/swipe-left.svg"
						class="m-auto absolute top-40 inset-x-0 pointer-events-none"
					/>
					<p class="text-xl absolute top-80 inset-x-0 text-center text-color-text font-bold">
						Swipe nach links <br />um vorgeschlagene Jobs<br />
						zu verwerfen.
					</p>
				</div>
			</NuxtSwing>
			<NuxtSwing
				v-show="tutorialIndex === 1"
				:config="swingConfigLike"
				class="current-stack cursor-pointer"
				@throwoutright="$emit('like')"
			>
				<div
					class="max-h-[550px] rounded-lg backdrop-blur-md bg-color-white/40 absolute inset-0 overflow-hidden"
				>
					<NuxtImg
						src="/svg/swipe-like.svg"
						class="absolute top-5 right-5 pointer-events-none rounded-full"
					/>
					<NuxtImg
						src="svg/swipe-right.svg"
						class="m-auto absolute top-40 inset-x-0 pointer-events-none"
					/>
					<p class="text-xl text-center absolute top-80 inset-x-0 text-color-text font-bold">
						Swipe nach rechts <br />
						und entdecke Jobs, die zu<br />
						deinem Suchprofil passen.
					</p>
				</div>
			</NuxtSwing>
		</template>
		<template v-else>
			<NuxtSwing
				v-show="tutorialIndex === 0"
				:config="swingConfigLike"
				class="current-stack cursor-pointer"
				@throwoutright="$emit('like')"
			>
				<div
					class="max-h-[550px] rounded-lg backdrop-blur-md bg-color-white/40 absolute inset-0 overflow-hidden"
				>
					<NuxtImg
						src="/svg/swipe-like.svg"
						class="absolute top-5 right-5 pointer-events-none rounded-full"
					/>
					<NuxtImg
						src="/svg/swipe-right.svg"
						class="m-auto absolute top-40 inset-x-0 pointer-events-none"
					/>
					<p class="text-xl text-center absolute top-80 inset-x-0 text-color-text font-bold">
						Swipe nach rechts <br />
						und entdecke Jobs, die zu<br />
						deinem Suchprofil passen.
					</p>
				</div>
			</NuxtSwing>
			<NuxtSwing
				v-show="tutorialIndex === 1"
				:config="swingConfigDislike"
				class="current-stack cursor-pointer"
				@throwoutleft="$emit('dislike')"
			>
				<div
					class="max-h-[550px] rounded-lg backdrop-blur-md bg-color-white/40 absolute inset-0 overflow-hidden"
				>
					<NuxtImg src="/svg/swipe-dislike.svg" class="absolute top-5 left-5 pointer-events-none" />
					<NuxtImg
						src="/svg/swipe-left.svg"
						class="m-auto absolute top-40 inset-x-0 pointer-events-none"
					/>
					<p class="text-xl absolute top-80 inset-x-0 text-center text-color-text font-bold">
						Swipe nach links <br />um vorgeschlagene Jobs<br />
						zu verwerfen.
					</p>
				</div>
			</NuxtSwing>
		</template>
	</div>
</template>

<script lang="ts">
import Direction from '@hokify/swing/dist/Direction.js';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import NuxtSwing from '@/components/user/application/NuxtSwing.vue';
import { useUserRootStore } from '@/stores/root';

export default defineComponent({
	name: 'SwipeDialogues',
	components: {
		NuxtSwing
	},
	emits: ['likeOrDislike', 'dislike', 'like'],
	data() {
		const swingConfigLike: {
			throwOutConfidence?: any;
			allowedDirections?: any;
		} = {};
		const swingConfigDislike: {
			throwOutConfidence?: any;
			allowedDirections?: any;
		} = {};
		return {
			swingConfigLike,
			swingConfigDislike
		};
	},
	computed: {
		...mapStores(useUserRootStore)
	},
	created() {
		this.swingConfigLike = {
			throwOutConfidence: this.swipeOffset,
			allowedDirections: [Direction.RIGHT]
		};
		this.swingConfigDislike = {
			throwOutConfidence: this.swipeOffset,
			allowedDirections: [Direction.LEFT]
		};
	},
	methods: {
		swipeOffset(xOffset, yOffset, element) {
			const xConfidence = Math.min((Math.abs(xOffset) / element.offsetWidth) * 3, 1);
			const yConfidence = Math.min((Math.abs(yOffset) / element.offsetHeight) * 3, 1);
			return Math.max(xConfidence, yConfidence);
		}
	},
	props: {
		tutorialIndex: { type: Number, default: 0 }
	}
});
</script>
