<template>
	<section class="swipecards-container">
		<AskSetupPushNotificationDialog
			@result="setupPush"
			@click-closed="$trackUserEvent?.('close_notifications', {})"
		/>
		<AskAddToHomeScreenDialog
			@result="addToHome"
			@click-closed="$trackUserEvent?.('close_add_to_home_screen', {})"
		/>
		<AskForAppRating />
		<AskForJobAlarm
			v-if="getJobFilter"
			@result="swipeJobAlarm"
			@click-closed="$trackUserEvent?.('close_jobalarm', { pageElement: 'popup' })"
		/>
		<AskForActiveSourcing
			v-if="user"
			:user="user"
			@mounted="maybeShowActiveSourcingModal"
			@result="setupActiveSourcing"
			@click-closed="$trackUserEvent?.('close_active_sourcing', {})"
		/>
		<UserFoundJobModal
			v-if="user"
			:user-found-job="userFoundJob"
			@mounted="maybeShowUserFoundJobModal"
		/>
		<SwipeDialogues :show-job-recommendation="showJobRecommendation" />
		<template
			v-if="
				(searchResultsList && searchResultsList.length > 0) ||
				loading ||
				!!suggestionsCurrentlyLoading
			"
		>
			<div v-if="showPageOverlay" class="page-overlay" />
			<client-only>
				<div
					ref="jobcards"
					:style="{ height: jobcardHeight, margin: jobcardMargin }"
					class="swipecards"
				>
					<template
						v-if="
							loading ||
							(!!suggestionsCurrentlyLoading && searchResultsList && searchResultsList.length === 0)
						"
					>
						<Spinner />
					</template>
					<template v-else>
						<div
							v-if="searchResultsList && searchResultsList.length > 1"
							ref="upcoming"
							class="upcoming-stack"
						>
							<template v-for="suggestion in searchResultsList.slice(0, -1)">
								<JobCard
									v-if="
										suggestion.type === 'matchrelation' &&
										suggestion.relation &&
										suggestion.relation.type === 'job'
									"
									:key="`stack-${suggestion.identifier}`"
									:suggestion="suggestion"
								/>
								<CompanyCard
									v-else-if="
										suggestion.type === 'matchrelation' &&
										suggestion.relation &&
										suggestion.relation.type === 'company'
									"
									:key="`stack-${suggestion.identifier}-company`"
									:suggestion="suggestion"
								/>
								<InfoCard
									v-else-if="suggestion.type && suggestion.type === 'info'"
									:key="`stack-${suggestion.identifier}-info`"
									:suggestion="suggestion"
								/>
								<p v-else :key="suggestion.identifier">no content</p>
							</template>
						</div>
						<NuxtSwing
							ref="jobcardswing"
							:config="swingConfig"
							class="current-stack cursor-pointer"
							@throwinend="dragReset"
							@throwin="cardReset"
							@throwoutright="likeRelation"
							@throwoutleft="dislikeRelation"
							@throwoutend="throwoutEnd"
							@dragstart="dragStart"
							@dragend="dragEnd"
						>
							<JobCard
								v-if="
									currentSuggestion &&
									currentSuggestion.type === 'matchrelation' &&
									currentSuggestion.relation &&
									currentSuggestion.relation.type === 'job'
								"
								:key="`stack-${currentSuggestion.identifier}`"
								:suggestion="currentSuggestion"
								@click="openJobDetail({ force: false, job: currentSuggestion.relation.obj })"
								@save-job="saveJob(currentSuggestion)"
							/>
							<CompanyCard
								v-else-if="
									currentSuggestion &&
									currentSuggestion.type === 'matchrelation' &&
									currentSuggestion.relation &&
									currentSuggestion.relation.type === 'company'
								"
								:key="`stack-${currentSuggestion.identifier}-company`"
								:suggestion="currentSuggestion"
								@click="openCompanyDetail"
							/>
							<InfoCard
								v-else-if="
									currentSuggestion && currentSuggestion.type && currentSuggestion.type === 'info'
								"
								:key="`stack-${currentSuggestion.identifier}-info`"
								:suggestion="currentSuggestion"
							/>
							<p v-else :key="currentSuggestion.identifier">no content</p>
						</NuxtSwing>
						<SwipeTutorial
							v-show="showSwipeTutorial"
							:tutorial-index="tutorialIndex"
							@like="triggerTutorial('like')"
							@dislike="triggerTutorial('dislike')"
							@likeOrDislike="triggerTutorial('likeOrDislike')"
						/>
					</template>
				</div>
				<div
					ref="swipe-actions"
					:style="{ opacity: searchResultsList && searchResultsList.length > 0 ? 1 : 0 }"
					class="swipe-actions flex relative justify-center"
				>
					<button class="undo" :disabled="showSwipeTutorial" @click="trigger('undo')">
						<HokIcon color="white" class="mx-auto" name="icon:undo" />
						<span>RÜCKGÄNGIG</span>
					</button>
					<button id="dislike-button" class="dislike" @click="trigger('dislike')">
						<HokIcon color="white" class="mx-auto" name="icon:swipe-left" :size="5" />
					</button>
					<button id="like-button" class="like" @click="trigger('like')">
						<HokIcon color="white" class="mx-auto" name="icon:swipe-right" :size="5" />
					</button>
					<button
						id="filter-button"
						ref="filter"
						class="filter"
						:disabled="showSwipeTutorial"
						@click="showFilter"
					>
						<HokIcon color="white" class="mx-auto" name="icon:filter" />
						<span v-if="hasFiltersSelected" class="company-filter" />
						<span>FILTER</span>
					</button>
				</div>
			</client-only>
		</template>
		<div v-else class="text-center mt-20">
			<h3>Keine passenden Jobs verfügbar.</h3>
			<HokButton class="mb-4" color="main" @click="showFilter"> Filter bearbeiten </HokButton>
			<br />
			<HokButton outline color="main" class="mb-20" @click="relationsStore.resetDiscarded">
				Verworfene Jobs wiederherstellen
			</HokButton>
			<template v-if="loggedIn">
				<h3>Verpasse nie wieder aktuelle Jobs:</h3>
				<HokButton outline color="main" class="mb-4" @click="openJobAlarmPage(getJobFilter)">
					Jobalarm aktivieren
				</HokButton>
			</template>
		</div>
		<NuxtImg
			v-if="!$isMobile.any"
			alt=""
			src="/pwa-core-nuxt3/svg/backgrounds/background-wave.png"
			class="absolute inset-x-0 bottom-0 mx-auto"
		/>
	</section>
</template>

<script lang="ts">
// eslint-disable-next-line import/extensions
import Direction from '@hokify/swing/dist/Direction.js';
import { markRaw, defineComponent, defineAsyncComponent } from 'vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { diff } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/diff';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { isNotAuthenticatedError } from '@hokify/pwa-core-nuxt3/errors/NotAuthenticatedError';
import JobCard from '@hokify/pwa-core-nuxt3/components/JobCard.vue';
import CompanyCard from '@hokify/pwa-core-nuxt3/components/CompanyCard.vue';
import InfoCard from '@hokify/pwa-core-nuxt3/components/InfoCard.vue';
import type {
	IAPICompany,
	IAPIJobFilter,
	IAPILoginUser,
	IAPIMatchForJobSeeker,
	APITypeObjectId,
	APIObjectType,
	IAPIJobForUser,
	IAPIMatchForJobSeekerListEntry
} from '@hokify/common';
import { buildJobFilterObject } from '@hokify/shared-components-nuxt3/lib/helpers/buildJobFilterObject';
import type { PropType, ComponentPublicInstance } from 'vue';
import { mapStores } from 'pinia';
import NuxtSwing from '@/components/user/application/NuxtSwing.vue';
import AskAddToHomeScreenDialog from '@/components/AskAddToHomeScreenDialog.vue';
import AskForJobAlarm from '@/components/AskForJobAlarm.vue';
import AskForActiveSourcing from '@/components/AskForActiveSourcing.vue';
import UserFoundJobModal from '@/components/UserFoundJobModal.vue';
import AskForAppRating from '@/components/AskForAppRating.vue';
import AskSetupPushNotificationDialog from '@/components/AskSetupPushNotificationDialog.vue';
import { prepareJobFilterObject } from '~/helpers/prepareJobFilterObject';
import SwipeDialogues from '@/components/SwipeDialogues.vue';
import SwipeTutorial from '@/components/SwipeTutorial.vue';
import { useMatchesStore } from '@/stores/matches';
import { useNotificationsStore } from '@/stores/notifications';
import { useRelationsStore, type ListType } from '@/stores/relations';
import { useUiStateStore } from '@/stores/uistate';
import { useUserJobFilterStore } from '@/stores/user-job-filter';
import { useUserProfileStore } from '@/stores/user-profile';
import { useUserJobAlarmStore } from '@/stores/user-job-alarm';
import { useLoginStore } from '@/stores/login';
import { useValuesStore } from '@/stores/values';
import { useUserRootStore } from '@/stores/root';
import { useFeatureFlagStore } from '@/stores/feature-flag';

function isHTMLElement(el): el is HTMLElement {
	return el instanceof HTMLElement;
}

function hasVueElement(vue: any): vue is ComponentPublicInstance {
	return vue && !!vue.$el;
}

function isNuxtSwing(obj: any): obj is typeof NuxtSwing {
	return obj && !!obj.stack !== undefined;
}

export default defineComponent({
	name: 'Swipe',
	components: {
		AskForJobAlarm,
		AskAddToHomeScreenDialog,
		AskSetupPushNotificationDialog,
		AskForActiveSourcing,
		AskForAppRating,
		NuxtSwing,
		JobCard,
		CompanyCard,
		InfoCard,
		SwipeDialogues,
		SwipeTutorial,
		UserFoundJobModal
	},
	data() {
		const swingConfig: {
			throwOutConfidence?: any;
			allowedDirections?: any[];
		} = {};

		const currentRadius = undefined as undefined | string;
		const pendingStackedAction = undefined as any;
		const activeSourcingLike = undefined as undefined | boolean;
		const storedMatchRelation = undefined as undefined | ListType;

		return {
			activeSourcingLike,
			currentRadius,
			EventBus,
			isDragging: false,
			jobcardHeight: '80vh',
			jobcardMargin: '20px auto 0',
			loading: false,
			pendingStackedAction,
			radiusOptions: [
				{ value: '10 km' },
				{ value: '20 km' },
				{ value: '30 km' },
				{ value: '50 km' }
			],
			showPageOverlay: false,
			showSwipeTutorial: false,
			storedMatchRelation,
			suggestionsDiversity: false,
			swipesSinceLastDialogAction: 0,
			swipesTillJobAlarmModal: 10,
			swingConfig,
			tutorialIndex: 0,
			showJobRecommendation: false
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		searchresults() {
			return this.relationsStore.searchresults;
		},
		loggedIn() {
			return this.loginStore.loggedIn;
		},
		match(): IAPIMatchForJobSeekerListEntry | IAPIMatchForJobSeeker | undefined {
			if (this.currentSuggestion?.type === 'matchrelation' && this.currentSuggestion?.relation) {
				return this.matchesStore.getMatchByRelation(this.currentSuggestion.relation);
			}
			return undefined;
		},
		privacy() {
			return this.user && this.user.privacy;
		},
		suggestionsCurrentlyLoading() {
			return this.relationsStore.suggestionsCurrentlyLoading;
		},
		searchResultsList(): ListType[] {
			return this.relationsStore.searchresults.list;
		},
		currentSuggestion() {
			return this.searchResultsList && this.searchResultsList.slice(-1)[0];
		},
		hasFiltersSelected(): boolean {
			return !!this.getJobFilter?.filters?.length;
		},
		getJobFilter(): IAPIJobFilter | undefined {
			return this.userProfileStore.obj?.jobFilter;
		},
		...mapStores(
			useFeatureFlagStore,
			useLoginStore,
			useMatchesStore,
			useNotificationsStore,
			useRelationsStore,
			useUiStateStore,
			useUserJobAlarmStore,
			useUserJobFilterStore,
			useUserProfileStore,
			useUserRootStore,
			useValuesStore
		)
	},
	created() {
		this.swingConfig = {
			throwOutConfidence: this.duringSwipe,
			allowedDirections: [Direction.LEFT, Direction.RIGHT]
		};

		if (this.getJobFilter?.radius) {
			this.currentRadius = `${this.getJobFilter?.radius?.toString()} km`;
		} else {
			this.currentRadius = '';
		}

		if (this.currentRadius) {
			if (this.radiusOptions.length < 5) {
				this.radiusOptions.unshift({ value: '0 km' });
			}
		}
	},
	beforeMount() {
		this.EventBus.$on('open-filter-menu', this.openFilterClick);
	},
	async mounted() {
		this.setCardLayout();

		window.addEventListener('resize', this.setCardLayout);

		if (lsTest() && localStorage.getItem('tutorialSwipeShown') === null) {
			this.showSwipeTutorial = true;
		}

		if (lsTest()) {
			this.swipesTillJobAlarmModal = parseInt(
				localStorage.getItem('swipesTillJobAlarmModal') || '10',
				10
			);
		}

		try {
			if (this.loginStore.loggedIn) {
				await this.notificationsStore.checkForNewNotifications();
			}
		} catch (err) {
			this.$nuxt.$errorHandler(err);
		}

		try {
			this.suggestionsDiversity =
				await this.featureFlagStore.featureFlagEnabledByName('suggestionsDiversity');
			await this.relationsStore.loadJobCards(this.suggestionsDiversity);
		} catch (err) {
			this.$nuxt.$errorHandler(err);
		}
		if (this.user?.recommendationFilter && this.user.testerGroup === 'B') {
			this.showJobRecommendation = true;
		}
	},
	beforeUnmount() {
		this.EventBus.$off('swipe-like');
		this.EventBus.$off('swipe-dislike');
		this.EventBus.$off('open-filter-menu', this.openFilterClick);
		this.EventBus.$off('set-job-filter');
		this.EventBus.$off('activate-jobalarm');
		this.EventBus.$off('done-more-info');

		if (import.meta.client) {
			window.removeEventListener('resize', this.setCardLayout);
		}
	},
	methods: {
		increaseSwipesTillJobAlarmModal() {
			if (lsTest()) {
				this.swipesTillJobAlarmModal = Math.min(this.swipesTillJobAlarmModal + 10, 30);
				localStorage.setItem('swipesTillJobAlarmModal', String(this.swipesTillJobAlarmModal));
			}
		},
		async openJobAlarmPage(selected = {}) {
			const jobFields = await this.valuesStore.getJobFields();
			const jobAlarmPage = markRaw(
				defineAsyncComponent(
					() => import('@hokify/shared-components-nuxt3/lib/pages/jobAlarmPage.vue')
				)
			);
			try {
				await this.$page.push(
					jobAlarmPage,
					{
						selected,
						jobFields,
						getPossibleFilter: this.userJobFilterStore.getPossibleFilter,
						user: this.user
					},
					{
						pageTitle: `Jobalarm einstellen`,
						name: 'job-alarm'
					}
				);
				this.EventBus.$on('set-job-filter', async jobFilterObj => {
					try {
						await this.userJobFilterStore.setFilter(jobFilterObj);
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async setJobAlarmAndRedirect(jobFilterObj) {
			await this.userJobAlarmStore.setJobAlarm(jobFilterObj);
			this.$trackUserEvent?.('activate_jobalarm_completed', {
				pageElement: 'nocardsleft'
			});
			await navigateTo({ path: '/pwa/joblist/jobalarm#jobalarm' });
		},
		async swipeJobAlarm(activate) {
			this.$modal.hide('askForJobAlarm');
			this.showPageOverlay = true;
			if (activate) {
				try {
					const tld = this.userRootStore.topLevelDomain;
					const { selectedFilters, selectedFields, searchMode, searchterm, currentLocation } =
						buildJobFilterObject(this.getJobFilter);

					const jobFilterObj = prepareJobFilterObject({
						currentLocation,
						searchMode,
						selectedFields,
						searchterm,
						selectedFilters,
						currentRadius: this.currentRadius,
						tld
					});

					await this.userJobAlarmStore.setJobAlarm(jobFilterObj);

					this.$snack.success({
						text: 'Der Jobalarm wurde erstellt.',
						button: 'Jobalarme anzeigen',
						action: async () => {
							await navigateTo({ path: '/pwa/joblist/jobalarm#jobalarm' });
						}
					});

					this.$trackUserEvent?.('activate_jobalarm_completed', {
						pageElement: 'popup'
					});
				} catch (err: any) {
					this.$snack.danger({
						text: 'Beim Anlegen deines Jobalarms ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.'
					});
					this.$nuxt.$errorHandler(err);
				} finally {
					this.showPageOverlay = false;
				}
			} else {
				this.showPageOverlay = false;
				this.$trackUserEvent?.('dismiss_jobalarm', { pageElement: 'popup' });
			}
		},
		addToHome(activate) {
			this.$modal.hide('addToHomeScreen');
			this.showPageOverlay = true;
			if (activate) {
				this.$trackUserEvent?.('accept_add_to_home_screen', {});
			} else {
				this.$trackUserEvent?.('decline_add_to_home_screen', {});
			}
			this.$addToHomeScreen(activate).then(() => {
				this.showPageOverlay = false;
			});
		},
		setupPush(activate) {
			this.$modal.hide('setupPushNotification');
			this.showPageOverlay = true;
			if (activate) {
				this.$trackUserEvent?.('accept_notifications', {});
			} else {
				this.$trackUserEvent?.('decline_notifications', {});
			}
			this.$askPushPermission(activate).then(() => {
				this.showPageOverlay = false;
			});
		},
		setupActiveSourcing(value: boolean) {
			this.$modal.hide('askForActiveSourcing');

			if (value) {
				this.$trackUserEvent?.('accept_active_sourcing', {});
			} else {
				this.$trackUserEvent?.('decline_active_sourcing', {});
			}

			if (value && (!this.privacy || !this.privacy.user_active_sourcing)) {
				this.$modal.show('askPrivacyForActiveSourcing');
				return false;
			}
			this.$modal.hide('askPrivacyForActiveSourcing');

			this.userProfileStore.saveSetting({
				type: 'activeJobSearcher',
				value
			});
		},
		openFilterClick() {
			const elm = this.$refs.filter;

			if (elm && isHTMLElement(elm)) {
				elm.classList.remove('bounce');
				// eslint-disable-next-line no-void
				void elm.offsetWidth;
				elm.classList.add('bounce');
			}
		},
		async swipeLikeListener() {
			this.activeSourcingLike = true;
			await this.$page.goBack();
			this.trigger('like');
		},
		async swipeDislikeListener() {
			this.activeSourcingLike = false;
			await this.$page.goBack();
			this.trigger('dislike');
		},
		async openCompanyDetail({
			force,
			company,
			audienceId,
			openedViaLike = false
		}: {
			force: boolean;
			company: IAPICompany;
			audienceId?: APITypeObjectId<APIObjectType.CompanyAudience>;
			openedViaLike: boolean;
		}) {
			if (this.showSwipeTutorial) {
				this.tutorialCompleted();
			}

			if (!this.isDragging || force) {
				const CompanyDetail = markRaw(
					defineAsyncComponent(() => import('@/pages/pwa/c/[companyId].vue'))
				);
				await this.$page.push(
					CompanyDetail,
					{
						companyId: company._id,
						swipeFcts: true,
						showApplyButton: false,
						audienceId,
						openedViaLike
					},
					{
						// useModal: false,
						pageTitle: company.name,
						route: 'pwa-c-companyId',
						done: async () => {
							this.EventBus.$off('swipe-like');
							this.EventBus.$off('swipe-dislike');
							this.EventBus.$off('activate-jobalarm');
							this.relationsStore.updateNumberOrId(undefined);
							await this.checkStackedActions();
							this.$page.goBack();
						}
					}
				);
				this.EventBus.$on('swipe-like', this.swipeDislikeListener);
				this.EventBus.$on('swipe-dislike', this.swipeDislikeListener);
				this.EventBus.$on('activate-jobalarm', async () => {
					try {
						await this.userJobAlarmStore.setJobAlarm({
							filters: [`company-${company.alias}`]
						});

						this.$snack.success({
							text: `Jobalarm für ${company.name} erstellt.`,
							button: 'Bearbeiten',
							action: async () => {
								await navigateTo({ path: '/pwa/joblist/jobalarm' });
							}
						});
						this.$trackUserEvent?.('activate_jobalarm_completed', {
							pageElement: 'swipe-card-discover'
						});
					} catch (err: any) {
						if (isNotAuthenticatedError(err)) {
							this.$snack.danger({
								text: 'Du musst angemeldet sein, um einen Jobalarm anlegen zu können.',
								button: 'Anmelden',
								action: async () => {
									await navigateTo({ path: '/pwa/login' });
								}
							});
							return;
						}
						this.$nuxt.$errorHandler(
							err,
							'Beim setzen deines Jobalarms ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.'
						);
					}
				});
				this.relationsStore.updateNumberOrId({
					idOrNr: company._id,
					audienceId
				});
			}
		},
		async openJobDetail({
			force,
			job,
			matchRelation
		}: {
			force: boolean;
			job: IAPIJobForUser;
			matchRelation?: IAPIMatchForJobSeeker;
		}) {
			if (this.showSwipeTutorial) {
				this.tutorialCompleted();
			}
			if (!this.isDragging || force) {
				const JobPage = markRaw(defineAsyncComponent(() => import('@/pages/pwa/job/[jobNr].vue')));
				await this.$page.push(
					JobPage,
					{
						jobNr: job.jobNr,
						swipeFcts: true,
						showApplyButton: false
					},
					{
						// useModal: false,
						pageTitle: job.name,
						route: 'pwa-job-jobNr',
						done: async () => {
							this.EventBus.$off('swipe-like');
							this.EventBus.$off('swipe-dislike');
							this.EventBus.$off('activate-jobalarm');
							this.relationsStore.updateNumberOrId(undefined);
							await this.$page.goBack();
						}
					}
				);
				this.EventBus.$on('swipe-like', this.swipeDislikeListener);
				this.EventBus.$on('swipe-dislike', this.swipeDislikeListener);
				this.EventBus.$on('done-more-info', async () => {
					// for active sourcing jobs we want to save the job if the user opens jobdetail via right swipe and doesn't
					// take any active sourcing action afterwards.
					if (
						job?.selectedCandidate &&
						matchRelation?.relation &&
						job?.activeSourcingContactStatus !== 'request-rejected' &&
						this.activeSourcingLike
					) {
						if (this.loggedIn) {
							this.uiStateStore.updateUnseenSavedJobs(true);
							await this.relationsStore.saveRelation(matchRelation.relation);
							let { name } = job;
							if (name.trim().length > 30) {
								name = `${name.trim().slice(0, 30)}...`;
							}
							this.$snack.success({
								text: `Der Job ${name} wurde gespeichert!`,
								button: 'Bewerben',
								action: () => {
									this.likeRelation(false, matchRelation);
								}
							});
						} else {
							this.$snack.danger({
								text: 'Du musst angemeldet sein, um einen Job speichern zu können.',
								button: 'Anmelden',
								action: async () => {
									await this.$deepLinkRouter.handleUrl(`/save/${job.jobNr}`);
								}
							});
						}
						// we remove the card from stack at the end of right swipe, so upon dislike we need to reset this
					} else if (this.storedMatchRelation) {
						this.relationsStore.undoRelationCard(this.storedMatchRelation);
					}
				});
				this.relationsStore.updateNumberOrId({ idOrNr: job.jobNr });
			}
		},
		setCardLayout() {
			// SET CORRECT HEIGHT FOR JOBCARDS
			let innerWidth = 768;
			let innerHeight = 512;

			if (import.meta.client) {
				({ innerWidth, innerHeight } = window);
			}

			// NOW CALCULATE
			const marginTop = 20;
			const buttonsBottom = 80;
			const header = innerWidth > 768 ? 65 : 50;
			const swipeCardHeight = innerHeight - marginTop - buttonsBottom - header;
			this.jobcardHeight = `${swipeCardHeight}px`;
			if (innerHeight > 750) {
				this.jobcardMargin = `${(innerHeight + 20 - 750) / 2}px auto 0`;
			}
		},
		noMoreJobs() {
			this.$trackUserEvent?.('no_more_jobs', { jobFilter: this.getJobFilter });
		},
		async showFilter() {
			const jobFilter = markRaw(
				defineAsyncComponent(() => import('../../../pages/pwa/jobfilter.vue'))
			);

			try {
				await this.$page.push(
					jobFilter,
					{ jobRecommendation: this.showJobRecommendation },
					{
						pageTitle: 'Jobs filtern',
						name: 'job-filter'
					}
				);
				this.EventBus.$on('set-job-filter', async jobFilterObj => {
					await this.userJobFilterStore.setFilter(jobFilterObj).then(() => {
						jobFilterObj?.filters?.forEach(filter => {
							this.$trackUserEvent?.('set_jobsearch_filter', {
								filterType: filter.split('-')?.[0],
								filterValue: filter.slice(filter.indexOf('-') + 1)
							});
						});
					});
					if (
						jobFilterObj?.filters?.length === 0 &&
						jobFilterObj?.location?.address === '' &&
						jobFilterObj?.region === undefined &&
						jobFilterObj?.search?.fieldIds?.length === 0 &&
						(jobFilterObj?.radius === undefined || jobFilterObj?.radius === 0)
					) {
						this.showJobRecommendation = true;
					} else {
						this.showJobRecommendation = false;
					}

					this.loading = true;

					// load new jobs
					await this.relationsStore
						.loadMoreCards(
							true,
							false,
							this.user?.testerGroup === 'B' && this.showJobRecommendation
						)
						.then(() => {
							this.$trackUserEvent?.('list_view', {
								relations: this.relationsStore.searchresults.list
									.filter(obj => obj.type === 'matchrelation')
									.map(
										obj =>
											obj.type === 'matchrelation' && {
												relation: obj.relation.type,
												relationId: obj.relation.obj._id,
												audienceId:
													(obj.relation.type === 'company' && obj.relation.audienceId) || undefined
											}
									)
							});
						})
						.catch(err => this.$nuxt.$errorHandler(err));
					this.loading = false;

					// reset card layout
					this.setCardLayout();

					this.$page.goBack();
				}); // listen to events which will be emitted from inside the component
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async trigger(action) {
			if (this.showSwipeTutorial) {
				if (this.tutorialIndex === 0 && action === 'like') {
					this.tutorialIndex += 1;
				} else if (this.tutorialIndex === 1 && action === 'dislike') {
					this.tutorialIndex += 1;
					this.tutorialCompleted();
				}
				return;
			}
			if (action === 'undo') {
				try {
					const lastUndoneCard = await this.relationsStore.undoDiscardRelation();
					if (!lastUndoneCard) {
						this.$snack.show({
							text: 'Die Aktion kann nicht weiter rückgängig gemacht werden.'
						});
					} else if (lastUndoneCard?.relation?.type === 'job') {
						this.$snack.success({
							text: `Der Job ${lastUndoneCard.relation.obj?.name} wurde wiederhergestellt.`
						});
					} else if (lastUndoneCard?.relation?.type === 'company') {
						this.$snack.success({
							text: `Die Firma ${lastUndoneCard.relation.obj?.name} wurde wiederhergestellt.`
						});
					} else if (lastUndoneCard?.type === 'info') {
						this.$snack.success({
							text: `Die Information ${lastUndoneCard.title} wurde wiederhergestellt.`
						});
					}
				} catch (err: any) {
					if (isNotAuthenticatedError(err)) {
						// silence
					} else {
						this.$nuxt.$errorHandler(err);
					}
				}
			} else {
				setTimeout(() => {
					// https://github.com/goweiwen/vue-swing/issues/1
					const currentIndex = this.searchResultsList.length - 1;
					const currentIdentifier = this.searchResultsList[currentIndex]?.identifier;
					let element;
					if (
						this.$refs.jobcardswing &&
						hasVueElement(this.$refs.jobcardswing) &&
						this.$refs.jobcardswing.$el
					) {
						[].slice.call(this.$refs.jobcardswing.$el.children).some(c => {
							if ((c as any).getAttribute('data-card-identifier') === currentIdentifier) {
								element = c;
								return true;
							}
							return false;
						});
					}

					if (element) {
						const card =
							this.$refs.jobcardswing &&
							isNuxtSwing(this.$refs.jobcardswing) &&
							(this.$refs.jobcardswing as any).stack &&
							(this.$refs.jobcardswing as any).stack.getCard(element);
						if (card) {
							card.throwOut(0, 0, action === 'like' ? Direction.RIGHT : Direction.LEFT);
							console.log('throw out now');
						} else {
							console.log('card not found,...?', currentIdentifier, element);
						}
					} else {
						console.log('no element found');
					}
				}, 150);
			}
		},
		triggerTutorial(action) {
			if (action === 'likeOrDislike' && !this.$isMobile.any) {
				this.tutorialIndex += 1;
				if (this.tutorialIndex > 1) {
					this.tutorialCompleted();
				}
			} else if (
				this.tutorialIndex === 0 &&
				action === 'like' &&
				this.$isMobile.any &&
				this.userRootStore.topLevelDomain !== 'de'
			) {
				this.tutorialIndex += 1;
			} else if (
				this.tutorialIndex === 1 &&
				action === 'dislike' &&
				this.$isMobile.any &&
				this.userRootStore.topLevelDomain !== 'de'
			) {
				this.tutorialIndex += 1;
				this.tutorialCompleted();
			} else if (
				this.tutorialIndex === 0 &&
				action === 'dislike' &&
				this.$isMobile.any &&
				this.userRootStore.topLevelDomain === 'de'
			) {
				this.tutorialIndex += 1;
			} else if (
				this.tutorialIndex === 1 &&
				action === 'like' &&
				this.$isMobile.any &&
				this.userRootStore.topLevelDomain === 'de'
			) {
				this.tutorialIndex += 1;
				this.tutorialCompleted();
			}
		},
		async likeRelation(e, providedRelation) {
			this.swipesSinceLastDialogAction += 1;
			let matchRelation;
			if (e) {
				const cardIdentifier = e.target.getAttribute('data-card-identifier');

				[matchRelation] = this.searchResultsList.filter(res => res.identifier === cardIdentifier);

				const cardClasses = e.target.getAttribute('class');
				e.target.setAttribute('class', `${cardClasses} fadeout-jobcard`);
			} else if (providedRelation) {
				matchRelation = providedRelation;
			} else {
				console.error('no relation available');
			}

			if (matchRelation?.relation?.type === 'job') {
				const job = matchRelation.relation.obj;
				if (job?.selectedCandidate) {
					try {
						await this.openJobDetail({
							force: true,
							job,
							matchRelation
						});
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}
				} else {
					const applyPage = markRaw(
						defineAsyncComponent(() => import('@/pages/apply/[jobNr].vue'))
					);
					try {
						this.relationsStore.updateNumberOrId({ idOrNr: job.jobNr });
						await this.$page.push(
							applyPage,
							{
								jobNr: job.jobNr,
								job,
								clickSource: 'user-card-swipe-interaction'
							},
							{
								pageTitle: job.name,
								route: 'apply-jobNr',
								done: async () => {
									if (this.loggedIn) {
										// check the state of this job, applied or saved?
										if (this.matchesStore.getStatusOfRelation(matchRelation.relation) === 'saved') {
											this.uiStateStore.updateUnseenSavedJobs(true);
											let { name } = job;
											if (name.trim().length > 30) {
												name = `${name.trim().slice(0, 30)}...`;
											}
											this.$snack.success({
												text: `Der Job ${name} wurde gespeichert!`,
												button: 'Bewerben',
												action: () => {
													this.likeRelation(false, matchRelation);
												}
											});
										} else {
											this.checkStackedActions();
										}
									} else {
										this.$snack.danger({
											text: 'Du musst angemeldet sein, um einen Job speichern zu können.',
											button: 'Anmelden',
											action: async () => {
												await this.$deepLinkRouter.handleUrl(`/save/${job.jobNr}`);
											}
										});
									}
									this.relationsStore.updateNumberOrId(undefined);
									this.$page.goBack();
								}
							}
						);
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}
				}

				/*		this.$trackUserEvent?.(
					job?.selectedCandidate ? 'card_swipe_right_active_sourcing' : 'card_swipe_right',
					{
						item_id: matchRelation.relation.obj._id,
						item_type: 'job'
					}
				); */
			} else if (
				matchRelation?.relation?.type === 'company' &&
				matchRelation?.relation?.obj?.swipeAction === 'application'
			) {
				const company = matchRelation.relation.obj;

				const applyPage = markRaw(
					defineAsyncComponent(() => import('@/pages/apply-company/[companyId].vue'))
				);
				try {
					this.relationsStore.updateNumberOrId({
						idOrNr: company._id,
						audienceId: matchRelation.relation.audienceId
					});
					await this.$page.push(
						applyPage,
						{
							companyId: company._id,
							audienceId: matchRelation.relation.audienceId,
							company,
							clickSource: 'user-card-swipe-interaction'
						},
						{
							pageTitle: company.name,
							route: 'apply-company-companyId',
							done: async () => {
								if (this.loggedIn) {
									// check the state of this company, applied or saved?
									if (this.matchesStore.getStatusOfRelation(matchRelation.relation) === 'saved') {
										this.uiStateStore.updateUnseenSavedJobs(true);
										let { name } = company;
										if (name.trim().length > 30) {
											name = `${name.trim().slice(0, 30)}...`;
										}
										this.$snack.success({
											text: `Die Firma ${name} wurde gespeichert!`,
											button: 'Bewerben',
											action: () => {
												this.likeRelation(false, matchRelation);
											}
										});
									} else {
										this.checkStackedActions();
									}
								} else {
									this.$snack.danger({
										text: 'Um eine Firma zu speichern, musst du eingeloggt sein!',
										button: 'Anmelden',
										action: async () => {
											await this.$deepLinkRouter.handleUrl(`/save-company/${company._id}`);
										}
									});
								}
								this.relationsStore.updateNumberOrId(undefined);
								this.$page.goBack();
							}
						}
					);
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}

				this.$trackUserEvent?.('card_swipe_right', {
					item_id: matchRelation.relation.obj._id,
					item_type: 'company'
				});
			} else if (
				matchRelation?.relation?.type === 'company' &&
				matchRelation?.relation?.obj.swipeAction === 'discover'
			) {
				const company = matchRelation?.relation?.obj;
				await this.openCompanyDetail({
					force: true,
					company,
					audienceId: matchRelation.relation.audienceId,
					openedViaLike: true
				});
				this.checkStackedActions();
			} else {
				try {
					this.relationsStore.discardRelation(matchRelation);
				} catch (err: any) {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		async dislikeRelation(e) {
			this.swipesSinceLastDialogAction += 1;

			const cardIdentifier = e.target.getAttribute('data-card-identifier');

			const [matchRelation] = this.searchResultsList.filter(
				searchResult => searchResult && searchResult.identifier === cardIdentifier
			);

			if (
				matchRelation?.type === 'matchrelation' &&
				matchRelation?.relation &&
				this.matchesStore.getStatusOfRelation(matchRelation.relation) !== 'saved'
			) {
				try {
					await this.relationsStore.discardRelation(matchRelation);
					this.$trackUserEvent?.('card_swipe_left', {
						item_id:
							(matchRelation.type === 'matchrelation' && matchRelation.relation.obj._id) ||
							undefined,
						item_type:
							(matchRelation.type === 'matchrelation' && matchRelation.relation.type) || undefined
					});
				} catch (err: any) {
					this.$nuxt.$errorHandler(err);
				}
			} else if (!matchRelation) {
				if (this.$sentry) {
					this.$sentry.withScope(scope => {
						scope.setExtra('matchRelation', matchRelation);
						this.$sentry.captureMessage('tried to discard non existing relation from stack');
					});
				} else {
					console.warn('discardRelation failed', e.target, matchRelation, this.searchResultsList);
				}
			}

			this.checkStackedActions();
		},
		async throwoutEnd(e) {
			const card =
				this.$refs.jobcardswing &&
				isNuxtSwing(this.$refs.jobcardswing) &&
				(this.$refs.jobcardswing as any).stack &&
				(this.$refs.jobcardswing as any).stack.getCard(e.target);

			// be sure that the card is gone from the stack
			const cardIdentifier = e.target.getAttribute('data-card-identifier');

			this.storedMatchRelation = this.searchResultsList.find(
				searchResult => searchResult.identifier === cardIdentifier
			);

			try {
				this.relationsStore.discardRelationCard(this.storedMatchRelation);
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
			if (card) {
				card.destroy();
			} // card is destroyed by vue already

			if (this.suggestionsCurrentlyLoading) {
				console.log('loading...');
				this.loading = true;
				await this.suggestionsCurrentlyLoading;
				this.loading = false;
			}

			if (this.searchResultsList.length === 0) {
				return this.noMoreJobs();
			}

			const newTop = this.searchResultsList[this.searchResultsList.length - 1];
			if (import.meta.client) {
				if (newTop.type === 'matchrelation') {
					const pageTitle = newTop.relation.obj.name;
					window.document.title = `${pageTitle} | hokify`;
				} else {
					window.document.title = 'Jobs finden und bewerben | hokify';
				}
			}
			this.isDragging = false;

			try {
				await this.relationsStore.loadJobCards(this.suggestionsDiversity);
				// still no jobs
				if (this.searchResultsList.length === 0) {
					this.noMoreJobs();
				}
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		dragReset() {
			this.isDragging = false;
		},
		cardReset(e) {
			[].slice.call(e.target.querySelectorAll('.overlay')).forEach(overlay => {
				(overlay as any).style.opacity = 0;
			});
		},
		duringSwipe(xOffset, yOffset, element) {
			if (!this.isDragging && Math.abs(xOffset) > 10) {
				this.isDragging = true;

				if (this.showSwipeTutorial) {
					this.tutorialCompleted();
				}
			}

			const xDirection = (parseInt(xOffset, 10) / parseInt(element.offsetWidth, 10)) * 1.7;

			// https://github.com/gajus/swing/issues/113
			if (xDirection > 0) {
				if (
					this.currentSuggestion?.type === 'matchrelation' &&
					this.currentSuggestion?.relation?.type === 'company' &&
					this.currentSuggestion?.relation?.obj?.swipeAction === 'discover'
				) {
					element.querySelector('.jobalarm-image').style.opacity = xDirection * 1.2;
				} else {
					element.querySelector('.like-image').style.opacity = xDirection * 1.2;
				}
			} else if (xDirection < 0) {
				element.querySelector('.dislike-image').style.opacity = -xDirection * 1.2;
			}

			// 0 - 0.7 << RANGE/DIRECTION
			// 0.8 - 1 << SCALE
			const cardRange = xDirection && xDirection < 0 ? -xDirection : xDirection;
			if (cardRange <= 0.81) {
				const calcScalePercent = (cardRange * 100) / 0.8;
				const calcScale = 0.8 + (0.2 * calcScalePercent) / 100;
				const nextCardElm = document.querySelector('.upcoming-stack .jobcard:last-of-type');
				if (nextCardElm && isHTMLElement(nextCardElm)) {
					nextCardElm.style.transform = `scale(${calcScale})`;
				}
			}

			// decide if throw was successful
			const xConfidence = Math.min((Math.abs(xOffset) / element.offsetWidth) * 3, 1);
			const yConfidence = Math.min((Math.abs(yOffset) / element.offsetHeight) * 3, 1);
			return Math.max(xConfidence, yConfidence);
		},
		async checkStackedActions() {
			// let them swipe at least 1x.
			if (this.swipesSinceLastDialogAction === 1) {
				const doItLaterStorage = lsTest() ? localStorage.getItem('AppRatingDoItLater') : null;
				const doItLater = doItLaterStorage !== null ? new Date(doItLaterStorage) : null;
				if (
					this.loggedIn &&
					process.env.cordova &&
					lsTest() &&
					localStorage.getItem('AppRatingDone') === null &&
					Object.keys(await this.matchesStore.getApplied())?.length > 0 &&
					(doItLater === null || diff(doItLater, 'days') > 7)
				) {
					this.swipesSinceLastDialogAction = 0;
					this.$modal.show('askForAppRating');
					this.$trackUserEvent?.('app_rating_view', {});
				}
			}

			if (this.swipesSinceLastDialogAction < 5) {
				return;
			}

			if (this.pendingStackedAction) {
				clearTimeout(this.pendingStackedAction);
				this.pendingStackedAction = false;
			}

			this.pendingStackedAction = setTimeout(() => {
				if (this.userRootStore.addToHomeScreen) {
					this.swipesSinceLastDialogAction = 0;
					this.$modal.show('addToHomeScreen'); // $addToHomeScreen
				} else if (this.loggedIn && this.userRootStore.setupPushNotification) {
					this.swipesSinceLastDialogAction = 0;
					this.$modal.show('setupPushNotification'); // $askPushPermission
				} else if (
					this.loggedIn &&
					this.user &&
					!this.user.askedForActiveSourcing &&
					!this.user.activeJobSearcher
				) {
					this.swipesSinceLastDialogAction = 0;
					this.$modal.show('askForActiveSourcing');
				} else if (
					this.swipesSinceLastDialogAction > this.swipesTillJobAlarmModal &&
					this.loggedIn &&
					!(this.getJobFilter as any).hasActivatedJobAlarm
				) {
					this.swipesSinceLastDialogAction = 0;
					this.increaseSwipesTillJobAlarmModal();
					this.$modal.show('askForJobAlarm');
				}
			}, 500); // 0.5 seconds delay before popping up something
		},
		async saveJob(matchRelation) {
			try {
				let { name } = matchRelation.relation.obj;
				if (name.trim().length > 30) {
					name = `${name.trim().slice(0, 30)}...`;
				}
				if (this.loggedIn) {
					if (
						matchRelation?.relation &&
						this.matchesStore.getStatusOfRelation(matchRelation.relation) !== 'saved'
					) {
						await this.relationsStore.saveRelation(matchRelation.relation);
						await this.$trackUserEvent?.('save_job', {
							item_id: matchRelation.relation.obj._id,
							item_type: 'job'
						});

						this.$snack.success({
							text: `Der  Job "${name}" wurde gespeichert!`
						});
					} else if (
						matchRelation?.relation &&
						this.matchesStore.getStatusOfRelation(matchRelation.relation) === 'saved' &&
						this.match
					) {
						await this.matchesStore.deleteMatch({ match: this.match });
						this.$snack.success({
							text: `Der gespeicherte Job "${name}" wurde gelöscht!`
						});
					}
				} else {
					this.$snack.danger({
						text: 'Du musst angemeldet sein, um einen Job speichern zu können.',
						button: 'Anmelden',
						action: async () => {
							await this.$deepLinkRouter.handleUrl(`/save/${matchRelation.relation.obj.jobNr}`);
						}
					});
				}
			} catch (err: any) {
				this.$nuxt.$errorHandler(
					err,
					'Beim Speichern des Jobs ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.'
				);
			}
		},
		tutorialCompleted() {
			this.showSwipeTutorial = false;
			if (lsTest()) {
				localStorage.setItem('tutorialSwipeShown', 'true');
			}
		},
		dragStart() {
			if (isHTMLElement(this.$refs.jobcards)) {
				this.$refs.jobcards.classList.add('jobcard-moving');
			}
		},
		dragEnd() {
			if (isHTMLElement(this.$refs.jobcards)) {
				this.$refs.jobcards.classList.remove('jobcard-moving');
			}
		},
		maybeShowActiveSourcingModal() {
			if (this.showActiveSourcingModal) {
				this.$modal.show('askForActiveSourcing');
			}
		},
		maybeShowUserFoundJobModal() {
			if (this.userFoundJob === 'yes' || this.userFoundJob === 'no') {
				this.$modal.show('userFoundJob');
			}
		},
		onSearchResultsChanged() {
			if (typeof this.$trackUserEvent !== 'undefined') {
				if (this.searchResultsList && this.searchResultsList.length > 0) {
					if (
						this.currentSuggestion.type === 'matchrelation' &&
						!this.$page.isOpen // do not trigger tracking, if insidePage is open, to prevent duplicate tracking of 'relationdetail-view'
					) {
						// tracking for remarketing
						if (this.currentSuggestion.relation.type === 'job') {
							const job = this.currentSuggestion.relation.obj;
							const country = job?.location?.countryCode
								? job?.location.countryCode.replace(/\W/g, '').toUpperCase()
								: undefined;

							if (job) {
								this.$trackUserEvent?.('relation_detail_view', {
									job: {
										jobNr: job.jobNr,
										_id: job._id,
										name: job.name,
										jobfields: job.fields.map(f => f.name),
										type: job.type,
										priorize: job?.internal.trackingValue || 1,
										country,
										city: job.location?.city || undefined
									},
									category: 'user-card-swipe-interaction',
									label: this.currentSuggestion.identifier
								});
							} else {
								// not quite sure if this can ever be the case.. but it was like this before
								this.$trackUserEvent?.('relation_detail_view', {
									category: 'user-card-swipe-interaction',
									label: this.currentSuggestion.identifier
								});
							}
						}
						// wait for tracking strategy from karl before adjusting company tracking
						else if (this.currentSuggestion.relation.type === 'company') {
							const company = this.currentSuggestion.relation.obj;
							const country = company?.location?.[0]?.countryCode
								? company.location[0].countryCode.replace(/\W/g, '').toUpperCase()
								: undefined;

							if (company) {
								this.$trackUserEvent?.('relation_detail_view', {
									company: {
										_id: company._id,
										name: company.name,
										jobfields: company.jobFields,
										country,
										city: company.location?.[0]?.city || undefined,
										audienceId: this.currentSuggestion.relation.audienceId
									},
									category: 'user-company-swipe-interaction',
									label: this.currentSuggestion.identifier
								});
							} else {
								// not quite sure if this can ever be the case.. but it was like this before
								this.$trackUserEvent?.('relation_detail_view', {
									category: 'user-company-swipe-interaction',
									label: this.currentSuggestion.identifier
								});
							}
						}
					}
				}
			}
		}
	},
	props: {
		showActiveSourcingModal: { type: Boolean, default: false },
		userFoundJob: { type: String as PropType<'yes' | 'no'>, required: false, default: undefined }
	},
	watch: {
		searchResultsList: [{ immediate: true, handler: 'onSearchResultsChanged' }]
	}
});
</script>

<style scoped lang="scss">
.swipecards-container {
	padding: 0 15px;
	.swipecards {
		position: relative;
		width: 100%;
		padding: 0;
		max-height: 567px;
		max-width: 375px;
		margin: $s5 auto 0;
	}
}

.swipe-actions {
	position: relative;
	button {
		margin: 5px;
		color: #a7a7a7;
		width: 66px;
		height: 66px;
		border-radius: 50%;
		outline: none;
	}
	.undo {
		width: 50px;
		height: 50px;
		padding: 3px;
		position: relative;
		background-color: $color-yellow;
		transition: color 600ms ease;
		&:hover,
		&:active {
			background-color: $color-yellow-hover;
			span {
				color: $color-yellow-hover;
			}
		}
		span {
			font-size: 10px;
			font-weight: bold;
			position: absolute;
			top: 57px;
			left: -18px;
			right: -15px;
			line-height: 10px;
			border-radius: 50%;
			color: $color-yellow;
		}
	}
	.like {
		background-color: $color-blue;
		transition: color 600ms ease;
		&:hover,
		&:active {
			background-color: $color-blue-hover;
		}
		//Static size because icon do not look like the same size
		// eslint-disable-next-line vue-scoped-css/no-unused-selector
		svg {
			height: 32px;
			width: 32px;
		}
	}
	.dislike {
		background-color: $color-purple;
		transition: color 600ms ease;
		&:hover,
		&:active {
			background-color: $color-purple-hover;
		}
		//Static size because icon do not look like the same size
		// eslint-disable-next-line vue-scoped-css/no-unused-selector
		svg {
			height: 28px;
			width: 28px;
		}
	}
	.filter {
		width: 50px;
		height: 50px;
		padding: 3px;
		position: relative;
		background-color: $color-main;
		transition: color 600ms ease;
		&:hover,
		&:active {
			background-color: $color-main-hover;
			span {
				color: $color-main-hover;
			}
		}

		span {
			font-size: 10px;
			font-weight: bold;
			color: $color-main;
			position: absolute;
			top: 57px;
			left: 0;
			right: 0;
			line-height: 10px;
			border-radius: 50%;

			&.company-filter {
				background-color: $color-purple;
				width: 10px;
				height: 10px;
				top: -7px;
				left: 42px;
			}
		}
	}
}
// gets added via Javascript
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.bounce {
	animation: bounce-in 0.5s;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes shake-right {
	0% {
		transform-origin: bottom;
		transform: translate(0%, 0%) rotate(0deg);
	}
	50% {
		transform-origin: bottom;
		transform: translate(0%, 0%) rotate(15deg);
	}
	100% {
		transform-origin: bottom;
		transform: translate(0%, 0%) rotate(0deg);
	}
}
@keyframes shake-left {
	0% {
		transform-origin: bottom;
		transform: translate(0%, 0%) rotate(0deg);
	}
	50% {
		transform-origin: bottom;
		transform: translate(0%, 0%) rotate(-15deg);
	}
	100% {
		transform-origin: bottom;
		transform: translate(0%, 0%) rotate(0deg);
	}
}
// gets added via Javascript
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.shake-right {
	animation-name: shake-right;
	animation-duration: 1s;
	animation-timing-function: linear;
}

// gets added via Javascript
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.shake-left {
	animation-name: shake-left;
	animation-duration: 1s;
	animation-timing-function: linear;
}

.page-overlay {
	position: fixed;
	box-sizing: border-box;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.2);
	z-index: 999;
	opacity: 1;
}
</style>
